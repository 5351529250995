.generalContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 90px;
    width: 100%;
    background-color: #6F3E98;
}

.insiderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
}