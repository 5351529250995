@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Lato-Regular.ttf") format("truetype");
}


@font-face {
    font-family: "Lato";
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato";
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Lato-Black.ttf") format("truetype");
}
