.generalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-color: #6F3E98;
  user-select: none;
  min-height: 900px;
  height: calc(100vh - 82px);
}

.insiderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.shieldContainer {
  margin-top: 35px;
  margin-bottom: 20px;
}

.title {
  color: #FCB614;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.subtitle {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 40px;
}

.label {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  margin-bottom: 18px;
}

.errorText {
  color: red;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  margin-bottom: 18px;
}

.button {
  background-color: #F17E21;
  border-radius: 100px;
  display: flex;
  width: 326.148px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.codeInputs {
  display: flex;
  justify-content: center;
  margin-bottom: 49px;
  gap: 4px;
  height: 51px;
}

.inputSegmentado {
  width: 51px !important;
  padding: 0px;
  margin: 0px;
  text-align: center;
  font-family: monospace;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 4px;
}

.errorInput {
  border: 3px solid red;
}

.generalContainer input {
  padding: 0px !important;
  margin: 0px !important;
}

.highlightedText {
  color: #FFF;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  display: inline;
}

.qrCode {
  margin-bottom: 50px;
}

.qrCode svg {
  width: 150px !important;
  height: 150px !important;
  border-radius: 20px;
}